<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <p>الملف الشخصي</p>
        <div class="form-group">
          <label for="">الاسم</label>
          <input type="text"
            class="form-control" v-model="user.name">
        </div>
        <div class="form-group">
          <label for="">اسم المستخدم</label>
          <input type="text"
            class="form-control" readonly v-model="user.username">
        </div>
        <div class="form-group">
          <label for="">الواتساب</label>
          <input type="text"
            class="form-control" v-model="user.whatsapp">
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="change_password" value="yes">
            تغيير كلمة المرور
          </label>
        </div>
        <div class="form-group" v-if="change_password">
          <br>
          <input class="form-control" v-model="old_password" rows="3" placeholder="كلمة المرور القديمة">
          <br>
          <input class="form-control" v-model="new_password" rows="3" placeholder="كلمة المرور الجديدة">
          <br>
          <input class="form-control" v-model="new_password2" rows="3" placeholder="تأكيد كلمة المرور الجديدة">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="save()">
                <i class="fa fa-save"></i>
                حفظ & تحديث البيانات
            </button>
        </div>
        <div class="col-12" v-if="error">
            <div class="alert alert-danger text-center g">
                <h4>
                    {{ error }}
                </h4>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            user: JSON.parse(localStorage.getItem("user")),
            old_password: "",
            new_password: "",
            new_password2: "",
            change_password: false,
            error: ""
        }
    },
    created(){
        var g = this;
    },
    methods: {
        save(){
            var g = this;
            g.error = ""
            $.post(api + '/admin/general/settings-save', {
                jwt: this.jwt,
                name: this.user.name,
                new_password: this.new_password,
                new_password2: this.new_password2,
                old_password: this.old_password,
                change_password: this.change_password,
                whatsapp: this.user.whatsapp,
                settings: "profile"
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                }else{
                    location.reload()
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        }
    },
}
</script>

<style>

</style>